<script>
import {mapGetters} from 'vuex';

export default {
  name: "MaintenanceNotice",
  data() {
    return {
      comment: '',
      isActive: 'n',
      speed: 1,
      now: Number(new Date()),
    }
  },
  computed: {
    ...mapGetters(['maintenanceNotice']),
    isEnd(){
      return this.now < Number(new Date(this.maintenanceNotice?.end_time));
    },
    isActiveNotice(){
      return this.isActive === 'y' && this.isEnd;
    },
  },
  watch:{
    maintenanceNotice(){

      this.comment = this.maintenanceNotice?.notice_contents;

      if( Number(new Date()) < Number(new Date(this.maintenanceNotice?.end_time)) ){
        this.isActive = 'y';
      }else{
        this.isActive = 'n';
      }
    },
    comment(){
      this.adjustSpeed();
    }
  },
  methods:{
    adjustSpeed() {
      // 브라우저 너비에 따라 속도를 계산 (뷰포트 너비에 비례)
      const viewportWidth = window.innerWidth;
      const adjustValue = 12;
      // const standardCommentLength = Math.round(viewportWidth / 10);
      const commentLength = viewportWidth >= 1000 ? 300 : 100;
      const calcLength = 0.5;
      const speedFactor = calcLength * adjustValue; //adjustValue 를 곱해줌으로써 속도조절
      const calculatedSpeed = Math.round(viewportWidth / commentLength) * speedFactor;
      this.speed = `${calculatedSpeed}s`;
      // console.log('MaintenanceNotice');
      // console.log(Math.round(viewportWidth / commentLength));
      // console.log(`viewportWidth: ${viewportWidth} / standardCommentLength: ${standardCommentLength} / commentLength: ${commentLength} / speedFactor: ${speedFactor}`);
      // console.log(`calcLength: ${calcLength} / adjustValue: ${adjustValue} / calculatedSpeed: ${calculatedSpeed}`);
    },
    updateNow() {
      this.now = Number(new Date()); // 1초마다 업데이트
    },
  },
  mounted() {
    setInterval(this.updateNow, 1000);
    window.addEventListener('resize', this.adjustSpeed);
    window.addEventListener('load', this.adjustSpeed);
  },
  destroyed() {
    clearInterval(this.updateNow);
    window.removeEventListener('resize', this.adjustSpeed);
    window.removeEventListener('load', this.adjustSpeed);
  }
}
</script>

<template>
  <div class="wrap-notice" v-if="isActiveNotice">
    <div class="notice">
      <div class="ico"><img src="@/assets/v2/svg/ico_alert.svg" alt="" width="18"></div>
      <div class="wrap-txt">
        <div class="marquee">
          <span :style="{animationDuration: speed}">
            {{ comment }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>